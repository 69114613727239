import React from "react";

export default function Footer() {
  return (
    <div className="footer w-100 text-white p-2 text-center" style={{backgroundColor: 'black'}}>
      <p className="my-0">Thank you for your time! ✌️</p>
      <small className="my-0">©️ 2023 Héctor Magaña</small>
    </div>
  );
}
