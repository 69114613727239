export var containerStyle = `text-center 
    border border-dark border-4 rounded-3
    p-1 py-3 
    mx-1 my-4 
    shadow bg-white`;
export var containerStyleBlack = `text-center 
    text-white
    border border-white border-4 rounded-3
    p-5 
    mx-1 my-4 
    shadow bg-black`;